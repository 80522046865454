<template lang="pug">
  v-container.px-10.px-lg-15.product-view(fluid='')
    v-overlay(color='white' :value='generalLoading')
      v-progress-circular(color='primary' indeterminate='' size='64')
    v-row(wrap='')
      v-col.pb-0(cols='12')
        v-toolbar(flat='')
          v-toolbar-title.title
            v-icon(@click='checkOrBack') mdi-arrow-left
            span.ml-4 {{ title }}
          v-spacer
          v-btn.ml-4(color='primary' v-if="hasSectionPermissions('delete')" outlined='' @click='openDeleteDialog')
            v-icon mdi-delete
            | Eliminar
        v-divider.ml-10(style='border-color: #B9C3C2;')
      v-col.pl-12.py-0(cols='12')
        v-row.mb-15
          v-col.pt-0(cols='4')
            v-form(v-model='valid' ref='form' style='width:100%')
              v-row.pl-3.pr-5
                v-col.px-0(cols="12")
                  h4 Información                
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' v-model='item.first_name' label='Nombres' color='1F3333' dense='' outlined='' :rules='rules.notEmpty')
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='loading' v-model='item.last_name' label='Apellidos' color='1F3333' dense='' outlined='' :rules='rules.notEmpty')
                v-col.px-0.py-0(cols="12")
                  v-text-field.mb-1(:disabled='$enums.ACTIONS.EDIT == this.action' hint="Los correos electrónicos NO SE PUEDEN CAMBIAR. Consulte con sistemas." persistent-hint label='Correo electrónico' type='email' color='1F3333' outlined='' v-model='item.email' :rules='rules.email' dense='')
                v-col.px-0.py-0(cols="12")
                  v-checkbox(v-model="item.disabled" dense label="Inhabilitado")
          v-col.pt-0(cols='8')
            v-row.mx-0
              v-col(cols="12")
                h4 Permisos
              v-col(cols="4")
                h3.mb-2 Sección
                v-card
                  v-list
                    v-list-item-group(v-model="sectionSelected")
                      v-list-item(v-for="section in permissionSections" :key="section.sectionValue" :value="section")
                        v-list-item-content
                          v-list-item-title {{section.sectionText}}
              v-col(cols="8")
                h3.mb-2 Permisos de la sección
                v-card(v-if="sectionSelected")
                  template(v-for="p in sectionSelected.permissions")
                    v-list-item
                      v-list-item-action
                        v-checkbox(v-model="currentPermissions.permissions" :multiple="false" :value="p.value" dense)
                          span(slot="label" v-html="p.text")
                          
                    v-divider

          
    dialog-info(ref='dialogInfo')
    dialog-confirm(@actionPressed='confirmDelete' ref='dialogConfirm')
    dialog-confirm-edit(ref='dialogConfirmEdit' @exit='back' @saveandexit='saveItem')
    .footer-products(v-if='action == $enums.ACTIONS.CREATE || action == $enums.ACTIONS.EDIT && hasSectionPermissions("edit")')
      v-btn(color='primary' :loading='loading' @click='back' primary='' outlined='' small='') CANCELAR
      v-btn.ml-6(color='primary' :loading='loading' @click='saveItem' small='' :disabled='!valid') {{ actionText }}

</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogConfirmEdit from "../../../components/shared/DialogConfirmEdit.vue";
import DialogInfo from "../../../components/shared/DialogInfo.vue";
export default {
  components: { DialogInfo, DialogConfirm, DialogConfirmEdit },
  async mounted() {
    this.generalLoading = true;
    let uid = this.$route.query.uid;
    this.action = this.$route.query.action;
    if (this.$enums.ACTIONS.CREATE == this.action) {
      this.item = this.generateItem();
    }
    if (this.$enums.ACTIONS.EDIT == this.action) {
      this.item = await this.$store.dispatch("getUser", uid);
      if(this.item.permissions){
        this.checkUndefinedPermissions(this.item)
        this.permissions = this.item.permissions
        //if(!this.permissions[])
      }
      this.copyOfItem = JSON.stringify(this.item);
    }
    this.generalLoading = false;
  },
  computed: {
    currentPermissions(){
      console.log(this.sectionSelected)
      console.log(this.permissions)
      return this.permissions[this.permissions.findIndex((p)=>p.section === this.sectionSelected.sectionValue)]
    },
    actionText() {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return "CREAR USUARIO";
      }
      if (this.$enums.ACTIONS.EDIT == this.action) {
        return "GUARDAR USUARIO";
      }
      return "";
    },
    title() {
      if (this.$enums.ACTIONS.CREATE == this.action) {
        return "Crear usuario";
      }
      if (this.$enums.ACTIONS.EDIT == this.action) {
        return "Editar usuario";
      }
      return "";
    },
  },
  data() {
    return {
      loading: false,
      action: "",
      generalLoading: false,
      key: 0,
      valid: false,
      errorMessage: "",
      copyOfItem: null,
      sectionSelected: null,
      
      permissions: [
        {section: 'pedidos', permissions: []},
        {section: 'categorias', permissions: []},
        {section: 'productos', permissions: []},
        {section: 'clientes', permissions: []},
        {section: 'proveedores', permissions: []},
        {section: 'transportistas', permissions: []},
        {section: 'usuarios', permissions: []},
        {section: 'configuracion', permissions: []},
        {section: 'corporativo', permissions: []},
        {section: 'cupones', permissions: []},
        {section: 'estadisticas', permissions: []},
      ],
      permissionSections: [
        {
          sectionText: "Pedidos",
          sectionValue: "pedidos",
          permissions: [
            {
              value: ["list"],
              text:
                "Solo podrá ver y cambiar de estado los pedidos Recibidos, Aceptados y En Camino.",
            },
            {
              value: ['list','history'],
              text: `Podrá ver los pedidos y cambiar sus estados, además de ver el historial de los pedidos <b>pero no el total de ventas del mes</b>`,
            },
            {
              value: ['list','history','amounts'],
              text: `Acceso completo a la sección "pedidos".`,
            },
          ],
        },
        {
          sectionText: "Categorias",
          sectionValue: "categorias",
          permissions: [
            {
              value: ['list'],
              text:
                "Solo podrá ver las categorías y subcategorías de los productos.",
            },
            {
              value: ['list','edit'],
              text: `Puede ver las categorías y subcategorías de los productos, además de crear y editar categorías y subcategorías.`
            },
            {
              value: ['list','edit','delete'],
              text: `Puede ver las categorías y subcategorías de los productos, además de crear, editar y <b> eliminar categorías y subcategorías.</b>`,
              required: [],
            },            
          ],
        },
        {
          sectionText:'Productos',
          sectionValue: 'productos',
          permissions: [
            {
              value: ['list'],
              text:
                "Solo podrá ver los productos de cada categoría.",
              required: [],
            },
            {
              value: ['list','edit'],
              text: `Puede ver los productos de cada categorías, además de crear o editar productos.`,
              required: [],
            },
            {
              value: ['list','edit','delete'],
              text: `Puede ver los productos de cada categorías, además de crear, editar y <b>eliminar productos.</b> `,
              required: [],
            },
          ]
        },
        {
          sectionText: "Clientes",
          sectionValue: "clientes",
          permissions: [
            {
              value: ['list'],
              text:
                "Solo podrá ver la información personal de los clientes.",
            },
            {
              value: ['list','edit'],
              text: `Puede ver <b>y editar</b> la información personal de los clientes`,
            },
            {
              value: ['list','edit','delete'],
              text: `Puede ver <b>y editar</b> la información personal de los clientes, además de <b>eliminar su información del sistema.</b>`,
            },
          ],
        },
         {
          sectionText: "Proveedores",
          sectionValue: "proveedores",
          permissions: [
            {
              value: ['list'],
              text:
                "Solo podrá ver la información de los proveedores.",
            },
            {
              value: ['list','edit'],
              text: `Puede ver la información de los proveedores y editar su información.`,
            },
            {
              value: ['list','edit','delete'],
              text: `Puede ver la informacion, editar y <b> eliminar proveedores.</b>`,
            },
          ],
        },
        {
          sectionText: "Transportistas",
          sectionValue: "transportistas",
          permissions: [
            {
              value: ['list'],
              text:
                "Solo podrá ver la información de los transportistas.",
            },
            {
              value: ['list','edit'],
              text: `Puede ver la información de los transportistas y editar su información.`,
            },
            {
              value: ['list','edit','delete'],
              text: `Puede ver la informacion, editar y <b> eliminar transportistas.</b>`,
            },
          ],
        },
        {
          sectionText: "Usuarios",
          sectionValue: "usuarios",
          permissions: [
            {
              value: ['list'],
              text:
                "Solo podrá ver la información de los usuarios.",
            },
            {
              value: ['list','edit'],
              text: `Puede ver la información de los usuarios y editar su información personal y permisos.`,
            },
            {
              value: ['list','edit','delete'],
              text: `Puede ver la informacion, editar y <b> eliminar usuarios.</b>`,
            },
          ],
        },
        {
          sectionText: "Configuración",
          sectionValue: "configuracion",
          permissions: [
            {
              value: ['web'],
              text:
                "Puede editar la información de la web (banners).",
            },
            {
              value: ['delivery'],
              text: `Puede editar la configuración de zonas de entrega, horarios de entrega y costos de delivery.`,
            },
            {
              value: ['web','delivery'],
              text: `Puede editar la información de la web (banners), zonas de entrega y costos de delivery.`,
            },
          ],
        },
        {
          sectionText: "Corporativo",
          sectionValue: "corporativo",
          permissions: [
            {
              value: ['list'],
              text:
                "Solo podrá ver la información de las empresas, planitllas y hubs.",
            },
            {
              value: ['list','edit'],
              text: `Podrá editar toda la información de las empresas`,
            },
            {
              value: ['list','edit','delete'],
              text: `Podrá listar, editar y <b> eliminar empresas o información relacionada a ellas.</b>`,
            },
          ],
        },

        {
          sectionText: "Cobranzas",
          sectionValue: "cobranza",
          permissions: [
            {
              value: ['list'],
              text:
                "Podrá ver la sección de cobranzas.",
            },
          ],
        },
      ],
      rules: {
        notEmpty: [(v) => !!v || "Debe llenar este campo"],
        email: [
          (v) => {
            if (v == "") {
              return true;
            }
            return (
              this.validateEmail(v) || "Ingrese un correo electrónico válido"
            );
          },
        ],
      },
      item: {
        first_name: "",
        last_name: "",
        email: "",
        uid: "",
        roles: [],
        disabled: false,
        super_admin: false,
        permissions: [],
      },
    };
  },
  methods: {
    hasSectionPermissions(...permissions){
      return this.$store.getters.hasSectionPermissions('usuarios',permissions)
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    checkUndefinedPermissions(item){
      this.$enums.PERMISSIONS.forEach((p)=>{
        const index = item.permissions.findIndex((p)=>p.section === p)
        if(index === -1){
          item.permissions.push( {section: p, permissions: []},)
        }
      })
    },
    newObjectItem() {
      return {
        first_name: "",
        last_name: "",
        email: "",
        uid: "",
        roles: [],
        disabled: false,
        super_admin: false,
        permissions: [],
      };
    },
    checkOrBack() {
      if (this.itemHasChanged()) {
        this.$refs.dialogConfirmEdit.open();
      } else {
        this.back();
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    itemHasChanged() {
      if (this.action == this.$enums.ACTIONS.EDIT) {
        let currentObject = JSON.stringify(this.item);
        if (currentObject == this.copyOfItem) {
          return false;
        }
        return true;
      }
      return false;
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch("deleteUser", this.item);
        this.$refs.dialogConfirm.stopLoading();
        this.$refs.dialogConfirm.close();
        this.$toast.success("Usuario eliminado satisfactoriamente");
        this.back();
      } catch (error) {
        this.$refs.dialogInfo(
          "Error al eliminar el usuario",
          error.response.data.error_message
        );
      }
    },
    openDeleteDialog() {
      this.$refs.dialogConfirm.open(
        "Eliminar usuario",
        "Si elimina el usuario su información se borrará permanentemente del sistema."
      );
    },
    async saveItem() {
      
      try {
        this.loading = true;
        this.generalLoading = false;
        this.permissions.forEach((p)=>{
          if(p.permissions === null){
            p.permissions = []
          }
        })
        this.item.permissions = this.permissions
        if (this.action == this.$enums.ACTIONS.CREATE) {
          await this.$store.dispatch("postUser", this.item);
          this.$toast.success("Usuario creado satisfactoriamente");
        } else if (this.action == this.$enums.ACTIONS.EDIT) {
          await this.$store.dispatch("putUser", this.item);
          this.$toast.success("Usuario editado satisfactoriamente");
        }
        this.$refs.dialogConfirmEdit.close();
        this.back();
      } catch (error) {
        this.errorMessage = error.response.data.error_message;
      }
      this.loading = false;
      this.generalLoading = false;
    },
    back() {
      this.$router.push(`/usuarios`);
    },
    generateItem() {
      let emptyValue = {
        first_name: "",
        last_name: "",
        email: "",
        uid: "",
        roles: [],
        disabled: false,
        super_admin: false,
        permissions: [],
      };
      return emptyValue;
    },
  },
};
</script>

<style lang="scss"></style>
